<template>
  <div class="inner-sm">
    <section class="tab-section">
      <BaseTab
        :tabValue="currentTab"
        :list="[
          $t('content.programs.Application.Tab.Apply'),
          $t('content.programs.Application.Tab.List'),
        ]"
        tabtype="type"
        @input="onTabChange"
      >
        <template v-slot="Scope">
          <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
        </template>
      </BaseTab>
    </section>

    <router-view
      :accPgmMngNo="accPgmMngNo"
    ></router-view>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseTab from '@/components/base/BaseTab.vue';

/**
 * 공모 지원 탭 index
 */
const TAB_INDEX_APPLY = 0;

/**
 * 공모 지원 내역 탭 index
 */
const TAB_INDEX_LIST = 1;

/**
 * Programs > 공모 지원하기 > 공모전 (공모지원 & 공모지원내역)
 */
export default {
  name: 'ProgramsApplicationDetail',
  mixins: [mixinHelperUtils],
  components: {
    BaseTab,
  },

  props: {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     */
    accPgmMngNoString: {
      type: String,
      required: true,
    },
  },

  computed: {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     * @type {number}
     */
    accPgmMngNo() {
      return +this.accPgmMngNoString;
    },

    /**
     * 현재 탭
     * @type {number}
     */
    currentTab() {
      const { path } = this.$route;

      if (/\/programs\/application\/(\d+)\/apply/.test(path)) {
        // 공모 지원 탭
        return TAB_INDEX_APPLY;
      } if (/\/programs\/application\/(\d+)\/my-apply/.test(path)) {
        // 공모 지원 내역 탭 (목록, 지원서 보기)
        return TAB_INDEX_LIST;
      }

      return -1;
    },
  },

  methods: {
    /**
     * 탭 선택 이벤트 처리
     * @param {number} tabIndex
     */
    onTabChange(tabIndex) {
      switch (tabIndex) {
        case TAB_INDEX_APPLY:
          // 공모 지원 탭 내에서는 이동하지 않도록 함
          if (this.currentTab !== tabIndex) {
            this.$router.push({
              name: 'ProgramsApplyForm',
            });
          }
          break;

        case TAB_INDEX_LIST:
          // 공모 지원 내역 탭에서는 지원서 보기에서 목록으로 이동할 수 있도록 함
          this.$router.push({
            name: 'ProgramsMyApplyList',
            params: {
              accPgmMngNoString: this.accPgmMngNo,
            },
          });
          break;

        default:
      }
      // }
    },
  },
};
</script>
